import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; 2020-2024 Lelantos Pte Ltd, Singapore. UEN 202016874N<br/>
         Contact Us: <a href="mailto:hello@lelantos.sg">hello@lelantos.sg</a></p>
        <p className="credit">This awesome template was designed by HTML5 UP - find more at https://html5up.net</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
